import { Component, OnInit, ViewChild, ElementRef ,Renderer2 ,HostListener} from '@angular/core';
import {faEnvelope , faBars ,faHeadset} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {

  @ViewChild('header',{static : true}) header: ElementRef;
  
  menuAtivado = false;
  faEnvelope = faEnvelope;
  faHeadset = faHeadset;
  faBars = faBars;
  constructor( private render:Renderer2) { }

  ngOnInit() {
  }

  @HostListener('window:scroll')onWindowScroll(){
    if(window.scrollY > 50){
    this.render.addClass(this.header.nativeElement,'header-top');
  }else{
    this.render.removeClass(this.header.nativeElement,'header-top');
  }
  }

  menuToogle(){
    this.menuAtivado = this.menuAtivado? false : true;
  }

  

}
