import { Component, OnInit, ViewChild, ElementRef, Renderer2, ViewChildren, AfterViewInit, QueryList } from '@angular/core';
import { faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit, AfterViewInit {

  @ViewChild('container', {static: false}) container: ElementRef;
  @ViewChild('container', {static:false}) moudura:ElementRef;
  @ViewChildren('elemento') elementos : QueryList<ElementRef>;
  faAngleDoubleLeft = faAngleDoubleLeft;
  faAngleDoubleRight = faAngleDoubleRight;
  public distanciaPercorrida : number;
  public intevalo : any;
  
  constructor( private render:Renderer2) {
    this.distanciaPercorrida = 0;
   }

  ngOnInit() {
    this.playCarrocel();
  }

  ngAfterViewInit(){
    this.render.setStyle(this.container.nativeElement , "transition", "transform ease 1s");
  }
  
  rolaDireita(){
   this.distanciaPercorrida +=this.elementos.first.nativeElement.width;
   if(this.distanciaPercorrida > 0 ){
    this.distanciaPercorrida = this.distaciaMaxima();
  }
   this.rolacarrocel();
  }

  rolaEsquerda(){
    this.distanciaPercorrida -=this.elementos.first.nativeElement.width;
      if(this.distaciaMaxima()  > this.distanciaPercorrida){
          this.distanciaPercorrida = 0;
      }
    this.rolacarrocel();
  }

  rolacarrocel(){
    this.render.setStyle(this.container.nativeElement , "transform","translate("+this.distanciaPercorrida+"px,0)");
    this.pauseCarrosel();
    this.playCarrocel();
  }

  playCarrocel(){
    this.intevalo = setInterval(()=>{
      this.rolaEsquerda();
    },5000);
  }
  pauseCarrosel(){
    clearInterval(this.intevalo);
  }

  distaciaMaxima():number {
    return -1 *(this.elementos.length * this.elementos.first.nativeElement.width - this.moudura.nativeElement.clientWidth);
  }
}
