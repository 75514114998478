import { Component, OnInit } from '@angular/core';
import {faEnvelope ,faHeadset,faMapMarkedAlt} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.css']
})
export class RodapeComponent implements OnInit {
faMapMarkedAlt = faMapMarkedAlt;
faEnvelope = faEnvelope;
faHeadset = faHeadset;

  constructor() { }

  ngOnInit() {
  }

}
