import { Component, ViewChild,AfterViewChecked } from '@angular/core';
import { RouterOutlet, Router,} from '@angular/router';
import { slideInAnimation } from './animations';
import { LoadComponent } from './load/load.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations:[slideInAnimation]
})
export class AppComponent implements AfterViewChecked{
  title = 'Auditabil Contabilidade';
  @ViewChild(LoadComponent ,{static:false}) load: LoadComponent;
  public routeLoading:boolean = false;

  constructor(private route:Router){
   
  }
  ngAfterViewChecked(): void {
    
  }
  

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


  abrirLoad(){
    this.load.open();
  }

  fecharLoad(){
    this.load.close();
  }

  

}
