import { Component, OnInit,Input, ElementRef, Renderer2, Injectable, ViewChild} from '@angular/core';


@Component({
  selector: 'load',
  templateUrl: './load.component.html',
  styleUrls: ['./load.component.css']
})

@Injectable({
  providedIn: 'root'
})
export class LoadComponent implements OnInit {

  @Input('image')image:string;
  @ViewChild('interna',{static:true})interna:ElementRef;
  isOpen:boolean;
  constructor(private render?:Renderer2) { 
    this.isOpen = false;
  }

  ngOnInit() {

  }

  open(){
    this.render.addClass(this.interna.nativeElement,"aberto");
    this.isOpen  = true;
  }

  close(){
    this.render.removeClass(this.interna.nativeElement,"aberto");
    this.isOpen = false;
  }
}
