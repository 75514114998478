import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppComponent } from './app.component';
import { CabecalhoComponent } from './cabecalho/cabecalho.component';
import { RodapeComponent } from './rodape/rodape.component';
import { RouterModule , Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadComponent } from './load/load.component';
import { ClientesComponent } from './home/clientes/clientes.component';


const appRoutes: Routes = [ 
  {path:"" ,redirectTo:"home", pathMatch:"full"},
  {path:"home", loadChildren: ()=>import('./home/home.module').then(m=> m.HomeModule), data:{animation:"page-home" }},
  {path:"contato", loadChildren: ()=>import('./contato/contato.module').then(m=> m.ContatoModule),data:{animation:"page-contato"}},
  {path:"nosso-espaco", loadChildren: ()=>import('./espaco/espaco.module').then(m=> m.EspacoModule ),data:{animation:"page-espaco"}},
  {path:"servicos", loadChildren: ()=>import('./servicos/servicos.module').then(m=> m.ServicosModule) ,data:{animation:"page-pageservico"}},
  {path:"blog", loadChildren: ()=>import('./blog/blog.module').then(m=> m.BlogModule) ,data:{animation:"page-blog"}},
  {path:"quem-somos", loadChildren: ()=>import('./sobre/sobre.module').then(m=> m.SobreModule) ,data:{animation:"page-sobre"}},
];


/*const appRoutes: Routes = [
  {path:"", redirectTo: 'home', pathMatch:'full'},
  {path:"home", component: PageHomeComponent ,data:{animation:"page-home"}},
  {path:"servicos", component: PageServicosComponent,data:{animation:"page-servico"} },
  {path:"nosso-espaco", component: PageNossoEspacoComponent ,data:{animation:"page-espaco"}},
  {path:"blog",component: PageBlogComponent, data:{animation:"page-blog"}},
  {path:"contato", component: PageContatoComponent, data:{animation:"page-contato"} },
  {path:"quem-somos", component: PageSobreComponent,data:{animation:"page-sobre"} },
  {path:"visualiza/:id", component: VisualizaPostComponent}
];*/
@NgModule({
  declarations: [
    AppComponent,
    CabecalhoComponent,
    RodapeComponent,
    LoadComponent,
    ClientesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FontAwesomeModule,
    RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled'
}),
    HttpClientModule,
    BrowserAnimationsModule,
  ],
 
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 

 
}
